<template>
  <section>
    <v-container class="container-custom">
      <v-form ref="form" v-model="valid" lazy-validation class="form-section">
        <v-row>
          <v-col cols="12" class="pb-0">
            <label for=""><b>Sub Category</b></label>
            <v-text-field
              v-model="name"
              placeholder="Sub Category"
              outlined
              class="mr-btm-minus"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <label for=""><b>Parent Category</b></label>
            <v-select
              placeholder="Category Name"
              v-model="categoryId"
              :items="allCategory.results"
              item-text="name"
              item-value="_id"
              outlined
            ></v-select>
          </v-col>
          <v-col md="12">
            <v-btn class="viewMore" v-if="loading" loading> </v-btn>
            <v-btn class="viewMore" v-if="!loading" @click="validate">Add
              {{ headercontent.title }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddSubcategory",
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    name: "",
    categoryId: undefined,
    description: "",
    categoryHandle: "",
    sort: 0,
    headercontent: {
      title: " Subcategory",
      addlink: "/dashboard/subcategory/add",
      downloadlink: true,
    },
    content: "<h2>I am Example</h2>",
    titleRules: [(v) => !!v || "Category Title is required"],
    statusRules: [(v) => !!v || "Status is required"],
    sortRules: {
      required: (value) => !!value || "Sort is required.",
      nan: (v) => !isNaN(v) || "Sort should be number",
    },
    descriptionRules: [(v) => !!v || "Description is required"],
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    imageUrl: null,
    id: undefined,
    percentage: 0,
    loading: false,
  }),
  methods: {
    removeImage() {
      this.imageUrl = "";
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let fd = new FormData();
        fd.append("name", this.name);
        fd.append("categoryId", this.categoryId);
        if (this.id != undefined) {
          fd.append("_id", this.id);
        }
        await this.postSubcategory(fd);
        this.loading = false;
      }
    },
    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log("Info", output.info);
      console.log("Exif", output.exif);
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    ...mapActions([
      "fetchCategory",
      "postSubcategory",
      "fetchSubCategoryBySlug",
    ]),
  },
  computed: {
    ...mapGetters(["allSubcategory", "allCategory"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  mounted() {},

  async created() {
    this.fetchCategory();
    if (this.$route.params.id != undefined) {
      await this.fetchSubCategoryBySlug(this.$route.params.id);
      this.name = this.allSubcategory.name;
      this.id = this.allSubcategory._id;
      this.categoryId = this.allSubcategory.category;
      this.percentage = this.allSubcategory.percentage;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.uploadedImage {
  width: 150px;
  padding: 20px;
}
.btnBox {
  margin-top: -15px;
}
.viewMore {
  min-height: 50px;
  width: 200px;
}
.imagebox {
  display: flex;
  max-width: 30%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.rmimagebox {
  position: relative;
  width: 200px;
  img {
    width: 100%;
  }
  .v-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.form-section {
  label {
    display: block;
    margin-bottom: 10px;
  }
}
</style>
